/** @jsx jsx */

import { jsx } from 'theme-ui'
import React, {
  FormHTMLAttributes,
  InputHTMLAttributes,
  AnchorHTMLAttributes,
  ReactNode
} from 'react'
import {
  Paper,
  AppBar,
  Button,
  ButtonGroup,
  CircularProgress
} from '@material-ui/core'
import { PaperProps } from '@material-ui/core/Paper'
import { AppBarProps } from '@material-ui/core/AppBar'
import { ButtonProps } from '@material-ui/core/Button'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary
} from '@material-ui/core'
import { ExpandLess, InfoOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { ButtonGroupProps } from '@material-ui/core/ButtonGroup'
import { theme } from 'data/theme'

import { flatMap, last } from 'lodash'
import he from 'he'
import { PushLink } from './Navigation'
import { MomentumLogo } from './MomentumLogo'
import BreadCrumbsBar, { BreadCrumb } from './BreadCrumbsBar'

import './elements.css'

export const useStyles = makeStyles(() => ({
  expanded: {},
  root: {
    '&$expanded': {
      minHeight: '48px !important',
      margin: '0px !important'
    }
  }
}))

export const Icon: React.FC<{ icon: string }> = ({ icon }) => {
  return icon.endsWith('svg') || icon.endsWith('png') ? (
    <img
      sx={{
        width: 80,
        height: 80,
        marginBottom: '-15px',
        marginRight: '-35px',
        zIndex: '999',
        position: 'relative'
      }}
      src={icon}
    />
  ) : (
    <span>{icon}</span>
  )
}

export const Panel: ViewElement<PaperProps & { noFooter?: boolean }> = ({
  children,
  noFooter,
  classes = useStyles(),
  ...props
}) => (
  <Paper
    elevation={4}
    sx={{
      display: 'grid',
      // Not applied properly. Don't know why.
      // pb: 3,
      // Needs to be important or overridden by theme styles.
      borderRadius: '0 !important',
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      WebkitOverflowScrolling: 'touch',
      userSelect: 'none'
    }}
    {...props}
  >
    {children}
    {!noFooter && (
      <ExpansionPanel
        sx={{
          variant: 'rows',
          alignItems: 'flex-start',
          justifyContent: 'flex-end',
          flexGrow: 1,
          margin: '0px !important',
          boxShadow: 'none !important'
        }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandLess />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          sx={{
            backgroundColor: '#F8F8F8 !important',
            width: '90%'
          }}
        >
          <InfoOutlined htmlColor="#e20316" />
          <p sx={{ margin: '0px 10px', color: 'primary' }}>About</p>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          sx={{
            flexDirection: 'column',
            backgroundColor: '#F8F8F8 !important'
          }}
        >
          <div sx={{ margin: '12.5px 0px' }}>
            Suggestions or bugs? Email{' '}
            <a sx={{ color: 'primary' }} href="mailto:info@peoplesmomentum.com">
              info@peoplesmomentum.com
            </a>
          </div>
          <div sx={{ margin: '12.5px 0px', display: 'flex', width: '100%' }}>
            Made by
            <MomentumLogo sx={{ marginLeft: '5px', color: '#e20316' }} />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )}
  </Paper>
)

export const ImagePanelHeaderCard: ViewElement<{ image: string }> = ({
  image,
  children,
  ...props
}) => (
  <PanelCard
    sx={{
      backgroundImage: `url("${image}")`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: '400px'
    }}
    {...props}
  >
    <div
      sx={{
        variant: 'heading',
        position: 'absolute',
        width: '100%',
        bottom: 0,
        pb: 2,
        color: 'white'
      }}
    >
      {children}
    </div>
  </PanelCard>
)

export const PanelSectionTitle: ViewElement<{
  bg?: string
  after?: React.ReactNode
}> = ({ children, after, ...props }) => (
  <PanelCard
    sx={{
      variant: 'columns',
      justifyContent: 'space-between',
      alignItems: 'center',
      pt: 4,
      pb: 2,
      fontFamily: 'heading'
    }}
    {...props}
  >
    <h2 sx={{ m: 0, fontWeight: 700 }}>{children}</h2>
    {after}
  </PanelCard>
)

export const CalloutCard: ViewElement = props => (
  <PanelCard
    bg="contrast.1"
    sx={{ variant: 'rows', mx: [0, 4], my: 4 }}
    {...props}
  />
)

export const CommaSeparated: ViewElement<{ children: ReactNode[] }> = ({
  children
}) => {
  children = children.filter(x => !!x)

  if (children.length < 2) {
    return <React.Fragment>{children[0]}</React.Fragment>
  }

  const list = flatMap(children.slice(0, children.length - 1), x => [x, ', '])
  list.splice(list.length - 1, 1)

  return (
    <React.Fragment>
      {list} and {last(children)}
    </React.Fragment>
  )
}

export const PanelCard: ViewElement<{
  sep?: string | true
  bg?: string
}> = ({ sep, bg = 'contrast.0', ...props }) => (
  <div
    sx={{
      background: 'white',
      position: 'relative',
      p: 3,
      flexShrink: 0,
      maxWidth: '100%',
      overflow: 'hidden',
      ':not(:last-child)': {
        borderBottom:
          typeof sep === 'string' ? sep : sep === true ? 'light' : 'none'
      }
    }}
    {...props}
  />
)

export const PanelHeader: ViewElement<AppBarProps & {
  breadCrumbs: BreadCrumb[]
}> = ({ children, ...props }) => {
  const { breadCrumbs } = props
  return (
    <AppBar color="primary" elevation={1} position="static" {...props}>
      <BreadCrumbsBar breadCrumbs={breadCrumbs} />
    </AppBar>
  )
}

export const PanelCardTitle: ViewElement = props => (
  <h3
    sx={{
      fontSize: 4,
      color: '#616161',
      fontWeight: 600,
      fontFamily: 'heading',
      m: 0,
      mb: 2
    }}
    {...props}
  />
)

export const FieldWrapper: ViewElement = props => (
  <div
    sx={{
      bg: '#EDEDED',
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'row',
      borderRadius: 3,
      overflow: 'hidden',
      position: 'relative'
    }}
    {...props}
  />
)

export const FormWrapper: ViewElement<
  {},
  FormHTMLAttributes<HTMLFormElement>
> = props => (
  <form
    {...props}
    onSubmit={e => {
      e.preventDefault()

      if (props.onSubmit) {
        props.onSubmit(e)
      }
    }}
  />
)

export const Input: ViewElement<
  {},
  InputHTMLAttributes<HTMLInputElement>
> = props => (
  <input
    sx={{
      p: 3,
      px: 2,
      fontSize: 4,
      fontWeight: 300,
      flexGrow: 1,
      height: '1.5em',
      bg: 'rgba(0,0,0,0)',
      outline: 'none',
      border: 'none'
    }}
    {...props}
  />
)

export const ProgressButton: ViewElement<
  { loading?: boolean },
  ButtonProps
> = ({ loading, children, className, disabled, ...props }) => (
  <div sx={{ position: 'relative' }} className={className}>
    <Button
      variant="contained"
      color="primary"
      disabled={loading || disabled}
      {...props}
    >
      {children}
    </Button>
    {loading && (
      <CircularProgress
        sx={{
          color: theme.colors.primary,
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12
        }}
        size={24}
      />
    )}
  </div>
)

export const ToggleGroup: ViewElement<ButtonGroupProps> = props => (
  <ButtonGroup color="primary" variant="outlined" {...props} />
)

const useToggleStyles = makeStyles(theme => ({
  root: (props: { active: boolean }) => ({
    pointerEvents: props.active ? 'none' : undefined,
    color: props.active
      ? theme.palette.getContrastText(theme.palette.primary.main)
      : undefined,
    backgroundColor: props.active ? theme.palette.primary.main : 'white'
  })
}))

export const ToggleButton: ViewElement<
  { value: string; onChange: (x: string) => void; optionValue: string },
  ButtonProps
> = ({ value, onChange, optionValue, ...props }) => (
  <Button
    classes={useToggleStyles({ active: optionValue === value })}
    onClick={() => onChange(optionValue)}
    {...props}
  />
)

export const LinkButton: ViewElement<
  GetComponentProps<typeof PushLink>,
  ButtonProps
> = props => (
  <Button
    sx={{ color: 'inherit', ':hover': { color: 'inherit' } }}
    component={PushLink}
    {...(props as any)}
  />
)

export const LinkButtonExternal: ViewElement<
  GetComponentProps<typeof Button>,
  AnchorHTMLAttributes<{}>
> = props => (
  <Button
    sx={{
      color: '#e20316',
      backgroundColor: '#fdebec !important',
      ':hover': {
        color: '#e20316'
      }
    }}
    component="a"
    target="_blank"
    {...(props as any)}
  />
)

export const Content: ViewElement<{ children: string }> = ({
  children,
  ...props
}) => (
  <div sx={{ variant: 'body', userSelect: 'text' }} {...props}>
    {he
      .decode(children || '')
      .split('\n')
      .map((paragraph, i) => (
        <p key={i} sx={{ m: 0, mt: i === 0 ? 0 : 2 }}>
          {paragraph.trim()}
        </p>
      ))}
  </div>
)

export const LoadingArea: ViewElement = ({ children, ...props }) => (
  <div
    sx={{
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      maxHeight: '20em',
      minHeight: '86px'
    }}
    {...props}
  >
    <div sx={{ position: 'relative' }}>
      <CircularProgress
        sx={{
          color: theme.colors.primary,
          position: 'absolute',
          top: '50%',
          left: '50%',
          marginTop: -12,
          marginLeft: -12
        }}
        size={32}
      />
    </div>
    <div sx={{ mt: 4, variant: 'description' }}>{children}</div>
  </div>
)

export const Chip: ViewElement = ({ children, ...props }) => (
  <div sx={{ variant: 'chip', px: 3, py: 1 }} {...props}>
    <div
      sx={{
        position: 'relative',
        fontWeight: 'bold'
      }}
    >
      {children}
    </div>
  </div>
)
